
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import {
  getConfigEnv,
  getComponent,
  sleep,
  getISOStringWithLocalOffset,
} from '@/utils/helpers';
import tasqFeedbackModule from '@/store/modules/tasqFeedbackModule';
import gptModule from '@/store/modules/gptModule';
import axiosClient from '@/lib/rest/axiosClient';
import { Debounce } from 'vue-debounce-decorator';
import metaDataModule from '@/store/modules/metaDataModule';
import GptMixin from '@/components/gpt/GptMixin';
import { mixins } from 'vue-class-component';
import { getAccessToken } from '@/lib/auth';
import {
  IDENTITY_POOL_ID,
  SCHEDULE_BUCKET_NAME,
  SCHEDULE_BUCKET_REGION,
} from '@/lib/constants';
import AWS from 'aws-sdk';
import accountModule from '@/store/modules/accountModule';
import uniqid from 'uniqid';
import setpointV2Module from '@/store/modules/setpointV2Module';
import { DateTime } from 'luxon';
import { getNameByEmail } from '@/utils/users';

@Component({
  components: {
    LastActionsSection: () => getComponent('gpt/LastActionsSection'),
    GptClusterOverview: () => getComponent('gpt/GptClusterOverview'),
    GptIssuesOverview: () => getComponent('gpt/GptIssuesOverview'),
    GptAutoSuggestion: () => getComponent('gpt/GptAutoSuggestion1'),
    GptMagicBox: () => getComponent('gpt/GptMagicBox'),
    PopupView: () => getComponent('tasqsCommon/PopupView'),
  },
})
export default class GptCommentBox extends mixins(GptMixin) {
  @Prop({ type: Object, required: true }) tasq?: any;

  @Prop({ type: Array, required: true }) waitingOnOptions?: any;

  recentLastActions: any = [];

  setActiveFileView: any = null;

  isLoadingFiles = false;

  isGptBoxFocused = false;

  hideMagicBox = false;

  isSavingData = false;

  startDate: any = new Date().toISOString();

  endDate: any = null;

  isWaitingOnType = false;

  showFilePreview = false;

  gptTextQuery = '';

  isTyping = false;

  isLoading = false;

  mediaFiles: any = [];

  isLoadingComplete = false;

  get showFileUploadLoading() {
    return this.isLoadingFiles;
  }

  get setpointSummary() {
    return gptModule.setpointSummary;
  }

  get plungerSummary() {
    return gptModule.plungerSummary;
  }

  setpointRec = {
    title: 'Setpoint Rec',
    color: 'bg-blue020',
    eventKey: 'show-setpoints',
    date: 'Jan 3 - Feb 14',
  };

  plungrRec = {
    title: 'Plunger Rec',
    color: 'bg-blue020',
    eventKey: 'show-plungers',
    date: 'Jan 3 - Feb 14',
  };

  fillOutForms = [
    {
      title: 'Plunger Change',
      color: 'bg-red005',
      date: 'Jan 3 - Feb 14',
    },
    {
      title: 'Site Visit',
      color: 'bg-yellow004',
      date: 'Jan 3 - Feb 14',
    },
  ];

  get responses() {
    return [
      {
        title: 'View Similar',
        color: 'bg-red005',
        date: 'Jan 3 - Feb 14',
        eventKey: 'show-view-similar',
        hover: false,
        hidden: !this.seeSimilars.length,
      },
      {
        title: 'Recommendation',
        color: 'bg-yellow004',
        date: 'Jan 3 - Feb 14',
        eventKey: 'show-recommendation',
        hover: false,
        hidden:
          !this.recommendation.length ||
          (this.recommendation.length && this.recommendation[0].length > 120),
      },
    ];
  }

  hoveringResponseTitle = '';

  get recommendation() {
    return gptModule.recommendations;
  }

  get disabledDates() {
    return [
      (date) => {
        const startDate = new Date(this.startDate);
        startDate.setHours(0, 0, 0, 0);
        date.setHours(2, 0, 0, 0);
        return date < startDate;
      },
    ];
  }

  get showPlungerItem() {
    let status = false;
    if (this.gptTextQuery) {
      const gptQuery = this.gptTextQuery.toLowerCase();
      status =
        (gptQuery.includes('plunger') ||
          gptQuery.includes('plungr') ||
          gptQuery.includes('plgr')) &&
        this.plungerSummary;
    }

    return status;
  }

  get showSetpointItem() {
    let status = false;
    if (this.gptTextQuery) {
      const gptQuery = this.gptTextQuery.toLowerCase();
      status =
        (gptQuery.includes('setpoint') ||
          gptQuery.includes('changepoint') ||
          gptQuery.includes('set point') ||
          gptQuery.includes('set pont') ||
          gptQuery.includes('set pint')) &&
        (this.setpointSummary || this.getCurrentWellSetpointData);
    }

    return status || this.isSetpointTasq;
  }

  get seeSimilars() {
    return gptModule.seeSimilars;
  }

  get getCurrentWellSetpointData() {
    return setpointV2Module.currentWellSetpointData;
  }

  get isSetpointTasq() {
    return this.tasq.engineerType === this.$getConst('TASQ_SETPOINT_TYPE');
  }

  get forms() {
    let forms: any = metaDataModule.formBuilderData;
    return forms.map((form) => ({
      validateForToday: false,
      isCompleteForToday: false,
      ...form,
    }));
  }

  get overviewHidden() {
    return gptModule.overviewHidden;
  }

  get selectedForms() {
    return tasqFeedbackModule.selectedForms;
  }

  updateWaitingOnType(data) {
    if (this.isWaitingOnType !== data) {
      this.startDate = new Date().toISOString();
      this.endDate = null;
    }
    this.isWaitingOnType = data;
  }

  openResponsePopup(response) {
    if (
      response.eventKey === 'show-recommendation' &&
      !this.recommendation.length &&
      this.recommendation[0].length > 120
    ) {
      return;
    }
    this.$emit(response.eventKey);
  }

  showActionModal(action) {
    this.$emit('show-action-modal', action);
  }

  selectActionOption(option) {
    let formExistIndex = -1;
    if (this.selectedForms && this.selectedForms.length) {
      // @ts-ignore
      formExistIndex = this.selectedForms.findIndex(
        (form) => form.id === option.id
      );
    }

    if (formExistIndex > -1) {
      tasqFeedbackModule.removeSelectedFrom(formExistIndex);
    } else {
      tasqFeedbackModule.pushSelectedForm(option);
    }
  }

  async setupDragAndDrop() {
    const dropContainer = document.getElementById('drop-container');
    const gptCommentContainer = document.getElementById('gpt-comment-box');

    // Prevent the default behavior of file dropping
    dropContainer.addEventListener('dragover', async (e) => {
      e.preventDefault();
      dropContainer.classList.add('drag-over');

      // Apply background and backdrop-filter styles when dragging
      // gptCommentContainer.style.background = '#0D2136';

      this.isLoadingFiles = true;
    });

    dropContainer.addEventListener('dragleave', () => {
      dropContainer.classList.remove('drag-over');

      // Restore the original background and backdrop-filter styles
      dropContainer.style.background = '';
      gptCommentContainer.style.backdropFilter = '';
      this.isLoadingFiles = false;
    });

    dropContainer.addEventListener('drop', async (e) => {
      e.preventDefault();
      dropContainer.classList.remove('drag-over');

      // Restore the original background and backdrop-filter styles
      // dropContainer.style.background = '';
      gptCommentContainer.style.backdropFilter = '';

      const files = e.dataTransfer.files;
      if (files.length > 0) {
        // Handle the dropped files here (e.g., upload or process them)
        await this.handleDroppedFiles(files);
        this.isLoadingFiles = false;
      }
    });
  }

  async processFileUpload(file) {
    const extension = file.name.split('.').pop().toLowerCase();

    const operator = getConfigEnv('OPERATOR_LOWERCASED');
    this.$emit('set-saving-details-banner-id', `Uploading "${file.name}"`);

    AWS.config.update({
      region: SCHEDULE_BUCKET_REGION,
      credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: 'us-east-1:e0f23ed4-2d5c-457e-807f-dcf561cdca7f',
      }),
    });

    const s3 = new AWS.S3({
      apiVersion: '2006-03-01',
      params: {
        Bucket: SCHEDULE_BUCKET_NAME,
      },
    });

    const unixTimestamp = Math.floor(Date.now() / 1000);
    // Splitting the filename and the extension
    const filenameParts = file.name.split('.pdf');

    // Inserting the unixTimestamp before the extension
    const modifiedFilename = `${filenameParts[0]}__${unixTimestamp}.pdf`;

    // Constructing the key with the modified filename
    const key = `${operator}/gptFiles/${accountModule.user.email.toLowerCase()}/${modifiedFilename}`;

    let contentType;
    if (extension === 'pdf') {
      contentType = 'application/pdf';
    }

    if (contentType) {
      this.isLoadingFiles = true;
      s3.upload(
        {
          Key: key,
          Bucket: SCHEDULE_BUCKET_NAME,
          Body: file,
          ContentDisposition: 'inline',
          ACL: 'public-read',
          ContentType: contentType,
        },
        async (err, data) => {
          if (err) {
            console.log(err, 'there was an error uploading your file');
            this.isLoadingFiles = false;
          } else {
            const s3_url = `https://${SCHEDULE_BUCKET_NAME}.s3.amazonaws.com/${key}`;

            const data = {
              id: await uniqid(),
              url: s3_url,
              name: file.name,
              size: file.size,
              type: file.type,
              isHoverActive: false,
            };
            this.mediaFiles.pop();
            this.mediaFiles.push(data);
            await this.uploadFileToRockset(data);
            this.isLoadingFiles = false;
          }
        }
      );
    } else {
      console.log('Unsupported file type');
    }
  }

  async uploadFileToRockset(data) {
    const operatorDetails = this.operatorList.find(
      (o) => o['appName'] === this.operatorName
    );
    const metaData = {
      url: data.url,
      wellMetaData: {
        ...(this.operatorName !== 'demo' && {
          operator_name: operatorDetails['operatorName'],
        }),
        ...(this.operatorName !== 'demo' && {
          operator_id: operatorDetails['operatorID'],
        }),
        nodeid: this.tasq.wellName,
        level:
          this.tasq && this.tasq.level ? this.tasq.level.toLowerCase() : 'well',
      },
    };

    const maxDuration = 120000; // 2 minutes in milliseconds
    const intervalDuration = 15000; // 15 seconds in milliseconds
    let intervalId = null;
    let timeoutId = setTimeout(() => {
      if (intervalId) clearInterval(intervalId);
    }, maxDuration);

    let isFirstQueueMessage = true;

    const tryUpload = async () => {
      const response = await gptModule.uploadFiles(metaData);
      if (
        response.message === 'Successfully added to queue' &&
        isFirstQueueMessage
      ) {
        gptModule.setNotfications({
          title: 'TASQ AI',
          message: 'AI is processing your file. Please wait for completion.',
          wellName: this.tasq.wellName,
        });
        isFirstQueueMessage = false;
      }
      if (response.finished) {
        clearInterval(intervalId);
        clearTimeout(timeoutId);
        await this.getGasLiftData();
        gptModule.setNotfications({
          title: 'TASQ AI',
          message: 'File processing is complete',
          wellName: this.tasq.wellName,
          duration: 30000,
        });
      }
    };

    intervalId = setInterval(tryUpload, intervalDuration);
    tryUpload(); // Initial call
  }

  async handleDroppedFiles(files) {
    console.log(files);

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      // Check if the file type is PDF or Excel
      if (file.type === 'application/pdf') {
        const url = URL.createObjectURL(file);

        this.mediaFiles = [
          {
            id: uniqid(),
            url,
            name: file.name,
            size: file.size,
            type: file.type,
            isHoverActive: false,
          },
        ];

        console.log(this.mediaFiles);
        await this.processFileUpload(file);
      } else {
        // File type isn't supported, show a more informative error alert
        this.$tasqAlert({
          title: 'Unsupported File Type',
          message: `The file "${file.name}" is not supported. Please upload only PDF.`,
          type: 'error',
        });
        console.warn(`Unsupported file type: ${file.name}`);
      }
    }
  }

  async removeFile({ id }) {
    this.mediaFiles = [];
    this.setActiveFileView = null;
    if (this.gasLiftDataID) {
      await axiosClient.delete(
        `/equipment-store/delete_by_nodeid/${this.tasq.wellName}`
      );
      await this.getGasLiftData();
    }
  }

  downloadURI({ url, name }) {
    const a = document.createElement('a');
    a.href = url;
    a.download = name || 'downloaded.pdf';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  gasLiftDataID: any = null;

  manualTasqs: any = [];

  manualTasqStep = 0;

  laterReplyManualTasqs = false;

  replyManualTasq = false;

  replyComment = '';

  get currentManualEntry() {
    if (this.manualTasqs.length && this.manualTasqs[this.manualTasqStep]) {
      console.log(this.manualTasqs[this.manualTasqStep]);
      return this.manualTasqs[this.manualTasqStep];
    }
    return null;
  }

  cancelReply() {
    this.replyComment = '';
    this.replyManualTasq = false;
  }

  nextManualEntry() {
    console.log('next');
    if (this.manualTasqStep === this.manualTasqs.length - 1) {
      this.manualTasqStep = 0;
    } else {
      this.manualTasqStep += 1;
    }
  }
  previousManualEntry() {
    console.log('prev');
    if (this.manualTasqStep === 0) {
      this.manualTasqStep = this.manualTasqs.length - 1;
    } else {
      this.manualTasqStep -= 1;
    }
  }

  async submitManualEntryReply() {
    if (this.replyManualTasq && this.replyComment) {
      this.isLoadingFiles = true;
      console.log(this.replyComment);
      console.log(this.currentManualEntry);

      try {
        await axiosClient.put(`/response-store/manual-tasq`, {
          id: this.currentManualEntry._id,
          data: {
            ...this.currentManualEntry['data'],
            reply_comment: this.replyComment,
          },
        });

        const metaData = {
          useAll: false,
          body: this.replyComment,
          summary: this.currentManualEntry.comment,
          type: 'Assigned Tasq',
          wellMetaData: {
            ...(this.operatorName !== 'demo' && {
              operator_name: this.operatorDetails['operatorName'],
            }),
            ...(this.operatorName !== 'demo' && {
              operator_id: this.operatorDetails['operatorID'],
            }),
            nodeid: this.tasq.wellName,
            level:
              this.tasq && this.tasq.level
                ? this.tasq.level.toLowerCase()
                : 'well',
          },
        };

        const payload = {
          query: '',
          stream: false,
          ...metaData,
        };
        console.log(payload);
        this.manualTasqs = this.manualTasqs.filter(
          (tasq) => tasq._id !== this.currentManualEntry._id
        );
        this.replyComment = '';
        this.manualTasqStep = 0;
        this.replyManualTasq = false;
        this.isLoadingFiles = false;
        await gptModule.postGptComment(payload);
        await gptModule.getAllActiveManualTasqs({
          operatorName: this.operatorDetails['operatorName'],
        });
      } catch (error) {
        console.log(error);
      }
    }
  }

  async getManualTasqs() {
    this.laterReplyManualTasqs = false;
    this.replyManualTasq = false;
    this.manualTasqStep = 0;
    const operatorDetails: any = this.operatorList.find(
      (o) => o['appName'] === this.operatorName
    );

    try {
      const { data }: any = await axiosClient.get(
        '/response-store/manual-tasqs',
        {
          params: {
            operator: operatorDetails['operatorName'],
            node_name: this.tasq.wellName,
            source_type: 'app',
            value_type: 'manual tasq',
            node_level: this.tasq.level,
          },
        }
      );

      if (data.length) {
        this.manualTasqs = data.map((item) => {
          return {
            comment: item['data'].comment,
            _id: item['_id'],
            addedBy: getNameByEmail(item['created_by']) || 'Test User',
            assignee: getNameByEmail(item['data'].assignee) || 'Test User',
            timeText: this.formatDate(item.time),
            data: item.data,
          };
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  formatDate(date) {
    const options: any = { month: 'short', day: 'numeric', year: 'numeric' };

    let dateObj = new Date(date);

    let startFormatted = dateObj.toLocaleDateString('en-US', options);

    return `${startFormatted}`;
  }

  get loadManualEntries() {
    return gptModule.loadManualEntries;
  }

  @Watch('loadManualEntries')
  async onLoadManualEntries(val) {
    if (val) {
      await this.getManualTasqs();
    }
  }

  async getGasLiftData() {
    gptModule.setGasLiftData(null);
    this.mediaFiles = [];
    this.gasLiftDataID = null;
    const operatorDetails: any = this.operatorList.find(
      (o) => o['appName'] === this.operatorName
    );

    await sleep(1000);

    const response: any = await axiosClient.get('/equipment-store/', {
      params: {
        operator: operatorDetails['operatorName'],
        nodeid: this.tasq.wellName,
        avg_casing_pressure: 200, //this.tasq.wellName
      },
    });

    const data = response.data;
    if (data && data.length) {
      const gasLiftData = data[0];
      console.log(data);
      gptModule.setGasLiftData(gasLiftData);
      if (gasLiftData && gasLiftData.url) {
        this.gasLiftDataID = gasLiftData._id;
        const urlParts = gasLiftData.url.split('/');

        // Get the last part of the URL
        const lastPart = urlParts[urlParts.length - 1];

        // Split the last part by '__' to remove the text after the last underscore
        const filenameParts = lastPart.split('__');

        // The filename is the first part before '__'
        const filename = filenameParts[0];

        const data = {
          id: await uniqid(),
          url: gasLiftData.url,
          name: filename,
          size: 0,
        };
        this.mediaFiles.push(data);
      }
    }
  }

  async submitComment() {
    if (this.manualTasqs.length && !this.laterReplyManualTasqs) {
      await this.submitManualEntryReply();
      return;
    }
    if (this.gptTextQuery.length < 2) {
      return;
    }
    const operatorDetails: any = this.operatorList.find(
      (o) => o['appName'] === this.operatorName
    );

    const metaData = {
      body: this.gptTextQuery,
      wellMetaData: {
        ...(this.operatorName !== 'demo' && {
          operator_name: operatorDetails['operatorName'],
        }),
        ...(this.operatorName !== 'demo' && {
          operator_id: operatorDetails['operatorID'],
        }),
        nodeid: this.tasq.wellName,
        level:
          this.tasq && this.tasq.level ? this.tasq.level.toLowerCase() : 'well',
      },
    };

    if (this.isWaitingOnType) {
      metaData['start_date'] = this.startDate
        ? getISOStringWithLocalOffset(this.startDate)
        : this.startDate;
      metaData['end_date'] = this.endDate
        ? getISOStringWithLocalOffset(this.endDate)
        : this.startDate
        ? getISOStringWithLocalOffset(this.startDate)
        : this.startDate;
      metaData['summary'] = '';
    }
    this.gptTextQuery = '';
    this.$emit('next-handler', metaData);
    this.hideMagicBox = true;
    this.$nextTick(() => {
      this.hideMagicBox = false;
    });
  }

  aiCommentSuggestions = [];

  isRecommendationLoaded = false;

  @Debounce(1000)
  async postGptComment(comment) {
    this.isLoadingComplete = false;
    if (!comment.length) {
      this.isTyping = false;
      return;
    }
    this.isTyping = true;

    const queryTime = new Date().getTime();
    gptModule.setQueryTime(queryTime);

    const operatorDetails: any = this.operatorList.find(
      (o) => o['appName'] === this.operatorName
    );

    const endpoints = [
      {
        url: '/ai/recommendations',
        stream: true,
        key: 'Recommendations',
        useMetadata: true,
        canRemoveOperator: true,
      },
      {
        url: '/ai/similar',
        stream: false,
        key: 'SeeSimilars',
        useMetadata: true,
        canRemoveOperator: true,
      },
      {
        url: '/ai/forms',
        stream: false,
        key: 'Forms',
        useMetadata: true,
        canRemoveOperator: false,
      },
      {
        url: '/ai/actions',
        stream: false,
        key: 'LastActions',
        useMetadata: true,
        canRemoveOperator: true,
      },
    ];

    let completedRequests = 0;
    tasqFeedbackModule.resetAllData();

    // Start all promises (backend requests) but don't wait for them to complete
    // Each request will set its value immediately upon completion
    endpoints.forEach(async (endpoint) => {
      try {
        gptModule[`reset${endpoint.key}`]([]);

        const metaData = {
          useAll: false,
          ...((this.operatorName === 'demo' ||
            endpoint.key === 'SeeSimilar' ||
            endpoint.key === 'LastActions') && {
            use_clean: true,
          }),
          wellMetaData: {
            ...((this.operatorName !== 'demo' ||
              !endpoint.canRemoveOperator) && {
              operator_name: operatorDetails['operatorName'],
            }),
            ...((this.operatorName !== 'demo' ||
              !endpoint.canRemoveOperator) && {
              operator_id: operatorDetails['operatorID'],
            }),
            nodeid: this.tasq.wellName,
            level:
              this.tasq && this.tasq.level
                ? this.tasq.level.toLowerCase()
                : 'well',
          },
        };

        gptModule.setGptQueryText(comment);
        if (endpoint.key == 'Recommendations') {
          this.isRecommendationLoaded = false;
          let stage = 'dev.';
          if (getConfigEnv('STAGE') == 'prod') {
            stage = '';
          }
          const url = `https://${stage}ai.tasq.io/ai/recommendations`;
          const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify({
              query: comment,
              stream: endpoint.stream,
              ...(endpoint.useMetadata && {
                ...metaData,
              }),
            }),
            headers: {
              'Content-Type': 'application/json',
            },
          });
          completedRequests++;
          const reader = response.body.getReader();
          const textDecoder = new TextDecoder();
          let str = '';
          const processStream = ({ done, value }) => {
            if (queryTime === gptModule.queryTime) {
              if (done) {
                this.isRecommendationLoaded = true;
                return;
              }
              const chunk = textDecoder.decode(value);
              str += chunk;
              gptModule[`set${endpoint.key}`]({
                recommendations: str,
                queryTime: queryTime,
              });
              return reader.read().then(processStream);
            }
          };

          reader.read().then(processStream);
          return;
        } else {
          const response = await axiosClient.post(endpoint.url, {
            query: comment,
            stream: endpoint.stream,
            ...(endpoint.stream && { responseType: 'stream' }),
            ...(endpoint.useMetadata && {
              ...metaData,
            }),
          });

          if (endpoint.key == 'Forms') {
            if (response.data && response.data.timescaleForms) {
              const form = this.forms.find(
                (f) => f.id === response.data.timescaleForms.id
              );

              tasqFeedbackModule.setSeletedForms([
                { ...form, isAiForm: true, fields: response.data.forms },
              ]);
              tasqFeedbackModule.setSeletedFormsFields(response.data.forms);
            }
          }

          gptModule[`set${endpoint.key}`]({ ...response.data, queryTime });
        }

        completedRequests++;
      } catch (error) {
        completedRequests++;
        console.error('Error fetching data for endpoint:', endpoint.url, error);
      } finally {
        if (completedRequests === endpoints.length) {
          gptModule.setForms(this.forms[0]);
          this.isLoading = false;
          this.isTyping = false;
          this.isLoadingComplete = true;
        }
      }
    });
  }

  async prepareGptFeedbackData() {
    try {
      const promises: any = [];

      promises.push(this.getManualTasqs());
      await gptModule.getSetpointSummary({
          operator: this.operatorDetails['operatorName'],
          node_name: this.tasq.wellName,
        })

      promises.push(
        setpointV2Module.getLatestSetpointRec({ nodeID: this.tasq.wellName })
      );
      await this.getGasLiftData();

      Promise.all(promises).then(
        async () => {},
        (err) => {
          console.log('Error:');
          console.log(err);
        }
      );
    } catch (error) {
      console.log(error);
    }
  }

  async mounted() {
    gptModule.resetLastActions();
    gptModule.setSetpointSummary(null);
    gptModule.setPlungerSummary(null);
    setpointV2Module.setCurrentWellSetpointData(null);
    this.setupDragAndDrop();
    await this.prepareGptFeedbackData()
  }
}
